import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

import csv_template from '../../assets/simcase_csv_template.csv'

import Header from '../../components/Header';
import CSVDrop from '../../components/CSVDrop';

// Helpers
import { setupSocket, socketEmit, socketOn } from '../../helpers/sockets';

// Actions
import { gameActions } from '../../actions';

class Form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            item: this.props.game.data || {},
            changed: false
        }

        // Bindings
        this.handleChange = this.handleChange.bind(this)
        this.submit  = this.submit.bind(this)
        this.launch  = this.launch.bind(this)
        this.readCSV = this.readCSV.bind(this)

    }

    componentDidMount(){

        const { dispatch } = this.props
        const { item } = this.state

        fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
        .then( res => res.json() )
        .then( data => {
            setupSocket(data.io_domain, () => {
                socketEmit('enter-backend', { game_id: item._id })
            })
            socketOn('joinplayer', data => {
                console.log({ data })
                dispatch( gameActions.fetchGames(item._id) )
            })
        })

    }

    componentWillReceiveProps(newProps){ 
        // Everytime REDUX updates the store
        // This hook will get executed and the state will get updated
        this.setState({ item: newProps.game.data });
    }

    handleChange(e){
        // console.log(e.target.value)
        let item = {...this.state.item}
        item[e.target.name] = e.target.name === 'players_text' ? e.target.value.replace(/\t/g, ', ') : e.target.value
        item['changed'] = true;
        this.setState({ item })
    }

    handleChangeCheckbox(n, v){
        let item = {...this.state.item}
        item[n] = v
        item['changed'] = true;
        this.setState({ item })
    }

    submit(){
        this.props.dispatch(gameActions.updateGame(this.state.item))
    }

    launch(){
        this.props.dispatch(gameActions.launchGame(this.state.item))
    }

    readCSV(content){
        let item = {...this.state.item}
        item.players_text = content
        this.props.dispatch(gameActions.updateGame(item))
    }

    render(){

        const { item } = this.state;

        // const okPPG = item.registered.length % item.ppg === 0 ? true : false;

        if( item.status === 'launched' )
            return <Redirect to={'/games/' + item._id + '/players'} />

        return <div className="">

            <Header />

            <h2 className="mt-3 sec-title" style={{ lineHeight: 1.3 }}>
                Game Setup <small 
                    style={{ fontSize: '12px', padding: '5px 8px', verticalAlign: 'middle' }} 
                    className={ item.status === 'saving' ? 'text-capitalize badge badge-pill badge-warning' : 'text-capitalize badge badge-pill badge-success' }>
                    { item.status === 'saving' ? 'saving...' : item.status }
                </small>
                <div className="float-right" style={{ lineHeight: 1 }}>
                    <button className="btn btn-md btn-outline-primary" onClick={ this.submit } disabled={ item.status === 'saving' ? true : false }>Save</button>
                    <button className="btn btn-md btn-outline-success ml-3" onClick={ this.launch } 
                        disabled={ item.status === 'saving' || item.changed }>Launch</button>
                </div>
            </h2>

            <div className="row mt-4">
                <div className="col-6">
                    <div className="row">
                        <div className="col-8">
                            <div className="form-group">
                                <label>Game Name</label>
                                <div className="input-group input-group-lg">
                                    <input name="name" className="form-control" placeholder="Enter game name" disabled={ item.status === 'saving' ? true : false }
                                    value={ item.name || '' } onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Access Type</label>
                                <div className="input-group input-group-lg">
                                    <select name="access_type" className="form-control" value={ item.access_type || '' } onChange={this.handleChange} disabled={ item.status === 'saving' ? true : false }>
                                        <option value="csv">CSV</option>
                                        <option value="link">LINK</option>
                                        {/* <option value="link">Link + Email</option> */}
                                    </select>
                                </div>
                            </div>
                        </div>
                        { item.access_type === 'link' && <div className="col-12 mt-4">
                            <div className="card">
                                <div className="card-body bg-dark text-center pt-5 pb-5 pl-5 pr-5">
                                    <code style={{ fontSize: '2rem' }}>{ item.short_url }</code>
                                </div>
                            </div>
                        </div> }

                        <div className="col-12 mt-2">
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    name="joineable" 
                                    onChange={ e => { this.handleChangeCheckbox('joineable', e.target.checked) }} 
                                    checked={ item.joineable }
                                    id="joineable" />
                                    <label className="form-check-label" htmlFor="joineable">
                                        Allow players to join the game <b>after</b> it has been launched.
                                    </label>
                            </div>
                        </div>

                        { item.access_type === 'csv' && <div className="col-12 mt-4">
                            <div className="card">
                                <div className="card-body text-left pt-3 pb-3 pl-3 pr-3">
                                    <h5 className="mb-3">Instructions</h5>
                                    <ol>
                                        <li className="mb-3">Download the .csv template <strong><a href={csv_template} target="_blank" rel="noopener noreferrer">here</a></strong>. Fill-in details for all participants, then copy &amp; paste to the text area at right.</li>
                                        <li className="mb-3">
                                            If you prefer, type each participant's email, first name <b>(optional)</b>, and surname <b>(optional)</b> in the text area at right. One per line, ie:
                                            <br/>
                                            <code>john@email.com, Jonh, Doe</code>
                                        </li>
                                        <li className="mb-0">Once you have entered all participants, save the game and launch it.</li>
                                    </ol>      
                                </div>
                            </div>
                        </div> }
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">

                        <label>Registered Players <span className="badge badge-warning badge-pill">{item.registered ? item.registered.length : 0}</span></label>

                        {/* { item.registered.length % item.ppg !== 0 && <small 
                            style={{ fontSize: '12px', padding: '5px 8px', verticalAlign: 'middle' }} 
                            className="badge badge-pill badge-danger float-right">
                            This game needs {item.ppg} players per group. Add more players.
                        </small> } */}

                        { item.access_type === 'csv' && <CSVDrop onComplete={ this.readCSV }>
                            <div className="input-group input-group-lg">
                                <textarea name="players_text" className="form-control" placeholder="email@example.com"
                                    value={ item.players_text || '' } onChange={this.handleChange} rows="15" disabled={ item.status === 'saving' ? true : false }></textarea>
                            </div>
                            <small><b>Tip</b>: You can also drag and drop your csv file here.</small>
                        </CSVDrop> }

                        { item.access_type === 'link' && <ul className="list-group">
                            { item.registered.map( rp => <li key={`li-${rp.email}`} className="list-group-item">{ rp.email }</li> ) }
                        </ul> }

                    </div>
                </div>
            </div>

        </div>
    }

}

function mapStateToProps(state) {
    const { game } = state;
    return {
        game
    };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameForm };
