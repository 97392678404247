import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';

// Helpers
// import { setupSocket, socketEmit, socketOn } from '../../helpers/sockets';

// import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
// import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
      scores: [],
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    if (this.props.match.params.id) {
      // this.props.dispatch(gameActions.fetchGames(this.props.match.params.id))
      fetch(`/api/games/${this.props.match.params.id}/scores`)
        .then((res) => res.json())
        .then((data) => {
          this.setState({ scores: data });
        });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({ item: newProps.item });
  }

  totalPoints(o) {
    let total = [0, 0];
    if (o.data) {
      for (let i = 0; i < o.data.length; i++) {
        const issue = o.data[i];
        const selected = issue.values.find((v) => v.selected);
        if (selected) {
          total[0] += selected.points[0];
          total[1] += selected.points[1];
        }
        if (i === o.data.length - 1) return total;
      }
    }
  }

  getMesos(offers) {
    return offers.filter((it, i) => {
      const prev = i > 0 ? offers[i - 1] : null;
      return prev && prev.order === it.order;
    });
  }

  extractValues(offer) {
    if (offer)
      return offer.data.map((issue) => issue.values.find((v) => v.selected));
    else return [];
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  render() {
    const { game } = this.props;
    const { scores } = this.state;

    const scoresList = scores.map((s) => {
      const avgtime =
        s.results.reduce((acc, r) => acc + r.qtime, 0) / s.results.length;
      return {
        name: s.nickname,
        email: s.email,
        truck: s.truck,
        revenue: { value: s.revenue, label: numeral(s.revenue).format('$0,0') },
        performance: {
          value: s.tperformance,
          label: numeral(s.tperformance).format('0,0') + '%',
        },
        qtime: Math.round(s.finalqtime),
        avgtime: Math.round(avgtime),
        date: moment(s.created_at).format('LLL'),
        duration: {
          value: s.duration ? s.duration : 0,
          label: s.duration ? moment.utc(s.duration).format('HH:mm:ss') : 0,
        },
      };
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Name',
              'Email',
              'Truck',
              'Revenue',
              {
                label: 'Performance',
                tooltip:
                  '% of the lowest possible average queue time.  In the game’s current configuration, 100% is 208.9s.',
              },
              {
                label: '3d Avg. Q. Time (s)',
                tooltip: 'Average queuing time across the three days.',
              },
              {
                label: 'Final Q. Time (s)',
                tooltip: 'Queuing time at the end of the third day.',
              },
              'Date',
              {
                label: 'Duration',
                tooltip:
                  'Time elapsed from initial decision to final submission.',
              },
            ]}
            fields={[
              'name',
              'email',
              'truck',
              'revenue',
              'performance',
              'avgtime',
              'qtime',
              'date',
              'duration',
            ]}
            sortables={[
              'name',
              'email',
              'truck',
              'revenue',
              'performance',
              'avgtime',
              'qtime',
              'date',
              'duration',
            ]}
            data={scoresList}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
